import React from 'react';
import { Link } from "gatsby";

const Breadcrumbs = props =>{
    return (
        <div className="breadcrumb-div mb-lg-5 pb-lg-0 mb-4" style={{position:"relative",zIndex:"9"}}>
            <nav aria-label="breadcrumb" className="mb-lg-4">
                <ol className="breadcrumb">
                    {props.props.map((item,index) =>(
                        <li className={'breadcrumb-item text-decoration-none '}> { (item.active ?  item.name : <Link className={'text-decoration-none '} to={item.route}>{item.name}</Link>)} </li>
                    ))}
                </ol>
            </nav>
        </div>
    )
}

export default Breadcrumbs